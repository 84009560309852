/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const wrapper = {
  mb: 3,
  display: 'flex',
  alignItems: 'center',
}

export const input = {
  outline: 'none',
  background: 'none',
  border: 'none',
  color: 'text',
  fontSize: 1,
}

export const icon = {
  color: 'border',
  mr: 2,
}
