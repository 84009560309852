/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as mixins from "~utils/mixins";

export const wrapper = {
  bg: "#fff",
  marginBottom: "2px",
  position: "relative",
  boxShadow: "0 2px 0 0 #f5f5f5"
};

export const innerContainer = {
  ...mixins.centerAlign,
  px: 4,
  py: "24px",
  position: "relative",
  justifyContent: "space-between"
};

export const headerButton = {
  ...mixins.centerAlign,
  outline: "none",
  p: "12px",
  border: "none",
  borderRadius: 9999,
  bg: "header.button.bg",
  color: "header.button.color",
  fontSize: 0,
  fontWeight: 600,
  ":hover": {
    cursor: "pointer"
  }
};

export const editButton = {
  ...mixins.centerAlign,
  position: "absolute",
  bottom: -40,
  right: 30,
  bg: "transparent",
  color: "muted",
  fontSize: 1,
  textDecoration: "none",
  borderRadius: "radius"
};
