/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

/** @jsx jsx */
/* eslint react/jsx-key: 0 */
import Highlight, { defaultProps } from "prism-react-renderer";
import { jsx, Styled } from "theme-ui";

import { usePrismTheme } from "~utils/theme";

export const Code = ({ children, className: outerClassName }) => {
  const [language] = outerClassName.replace(/language-/, "").split(" ");
  const theme = usePrismTheme();

  return (
    <Highlight
      {...defaultProps}
      code={children.trim()}
      language={language}
      theme={theme}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <Styled.pre
          className={`${outerClassName} ${className}`}
          style={{ ...style, whiteSpace: "pre-wrap" }}
        >
          {tokens.map((line, i) => (
            <div {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span
                  {...getTokenProps({ token, key })}
                  sx={{ display: "inline-block" }}
                />
              ))}
            </div>
          ))}
        </Styled.pre>
      )}
    </Highlight>
  );
};
