/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const logo = {
  letterSpacing: '-0.02em',
  fontWeight: 600,
  fontSize: 4,
}

export const link = {
  fontWeight: 600,
  color: 'header.text',
  textDecoration: 'none',
  ':hover': {
    color: 'primary',
  },
}
