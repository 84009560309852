/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";
import PropTypes from "prop-types";
import { useComponents } from "docz";
import { MDXProvider } from "@mdx-js/react";
import { propEq, get } from "lodash/fp";

import { useDbQuery } from "../hooks/useDbQuery";
import Wrapper from "../wrapper";
import Theme from "../index";
import SEO from "./Seo";
import Login from "./Login";
import { Auth0Provider } from "@auth0/auth0-react";

const Route = ({ children, entry, ...defaultProps }) => {
  const components = useComponents();
  const NotFound = components.notFound;
  const Layout = components.layout;
  const props = { ...defaultProps, doc: entry };

  if (!entry) return <NotFound />;
  return (
    <MDXProvider components={components}>
      <Wrapper>
        <Layout {...props}>{children}</Layout>
      </Wrapper>
    </MDXProvider>
  );
};

const findEntry = (db, ctx) => {
  const isIndex = ctx.frontmatter.route === "/";
  const eqIndex = propEq("value.route", "/");
  if (!ctx.entry && isIndex) return db.entries.find(eqIndex);
  const filepath = get("entry.filepath", ctx);
  return db.entries.find(propEq("value.filepath", filepath));
};

const Layout = ({ children, ...defaultProps }) => {
  const { pageContext: ctx } = defaultProps;
  const db = useDbQuery();
  const entry = findEntry(db, ctx);

  return (
    <>
      <Theme db={db} currentEntry={entry}>
        <Auth0Provider
          domain={process.env.GATSBY_OAUTH_DOMAIN}
          clientId={process.env.GATSBY_OAUTH_CLIENT_ID}
          redirectUri={process.env.GATSBY_REDIRECT}
          useRefreshTokens={true}
          cacheLocation="localstorage"
        >
          <Login>
            {entry && <SEO title={entry.value.name} />}
            <Route {...defaultProps} entry={entry}>
              {children}
            </Route>
          </Login>
        </Auth0Provider>
      </Theme>
    </>
  );
};

Layout.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default Layout;
